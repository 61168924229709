import Swiper from "swiper";

/**
 * Block images
 */
VIV.behaviours.testimonialSlider = {

    // Attach behaviour
    attach: function (context) {
        context.querySelectorAll('.blockTestimonials__items').forEach(function (element) {
            const arrowClass = 'teaserSlider__navItem';
            const testimonialSlider = new Swiper(element, {
                slidesPerView: 'auto',
                loop: false,
                watchOverflow: true,
                spaceBetween: 15,
                navigation: {
                    nextEl: element.querySelector('.' + arrowClass + '--next'),
                    prevEl: element.querySelector('.' + arrowClass + '--prev'),
                    disabledClass: arrowClass + '--disabled',
                    hiddenClass: arrowClass + '--hidden',
                    lockClass: arrowClass + '--lock'
                },
                breakpoints: {
                    480: {slidesPerView: 2, spaceBetween: 30},
                }
            });


            testimonialSlider.on('breakpoint', (instance, params) => {
                if (params.slidesPerView === 'auto') {
                    // Reset width set by swiper
                    element.querySelectorAll('.swiper-slide').forEach(slide => slide.style.width = 'auto');
                }
            });
        });
    }
};
