VIV.behaviours.submenu = {
    attach: function (context) {
        const dropdownClose = context.querySelector('.dropdown__close');
        const logoSection = context.querySelector('.logo__section');

        if (dropdownClose && logoSection) {
            dropdownClose.addEventListener('click', (e) => {
                e.preventDefault();
                logoSection.classList.remove('active');
            });
        }

        if (logoSection) {
            logoSection.addEventListener('click', (clickEvent) => {
                if (clickEvent.target !== dropdownClose && !logoSection.classList.contains('active')) {
                    logoSection.classList.toggle('active');
                }
            });
        }
    }
};