import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

VIV.behaviours.video = {
   attach: function(context) {
       context.querySelectorAll('.videoPlayer').forEach(element => {
           const conf = {};
           if (element.hasAttribute('data-autoplay')) {
               conf.autoplay = element.getAttribute('data-autoplay');
           }
           let player = new Plyr(element, conf);
       });
   }
};

