(function ($) {
    /**
     * Add class to body with touch info
     */
    var touchClass = function() {
        function hasTouch() {
            return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        }
        if (hasTouch()) {
            $('body').addClass('deviceHasTouch');
        } else {
            $('body').addClass('deviceNoTouch');
        }
    };

    // DOM Ready
    $(function() {
        touchClass();
    });

})(jQuery);
