var VIV = {
    behaviours : {},
    attachBehaviours : function (context) {
        jQuery.each(VIV.behaviours, function () {
            if (jQuery.isFunction(this.attach)) {
                this.attach(context, {});
            }
        });
    }
};

// Make VIV a global;
window.VIV = VIV;

// Attach bahaviours on page load with loaded page as context
jQuery(document).ready(function () {
    var rootContext = document.documentElement;
    VIV.attachBehaviours(rootContext);
});


