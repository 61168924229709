import Swiper, {Pagination} from "swiper";

Swiper.use([Pagination]);


/**
 * Block images
 */
VIV.behaviours.peopleSlider = {

    // Attach behaviour
    attach: function (context) {
        context.querySelectorAll('.blockPeople').forEach(function (element) {
            const pagerItemClass = 'slidePager__item';
            const peopleSlider = new Swiper(element.querySelector('.blockPeople__items'), {
                slidesPerView: 'auto',
                loop: false,
                watchOverflow: true,
                spaceBetween: 15,
                pagination: {
                    el: element.querySelector('.slidePager'),
                    lockClass: 'slidePager--lock',
                    type: 'bullets',
                    clickable: true,
                    clickableClass: `slidePager--clickable`,
                    bulletClass: pagerItemClass,
                    bulletActiveClass: `${pagerItemClass}--active`,
                    currentClass: `${pagerItemClass}--current`,
                },
                breakpoints: {
                    1025: {slidesPerView: 3, spaceBetween: 25},
                    600: {slidesPerView: 2, spaceBetween: 15}
                }
            });


            peopleSlider.on('breakpoint', (instance, params) => {
                if (params.slidesPerView === 'auto') {
                    // Reset width set by swiper
                    element.querySelectorAll('.swiper-slide').forEach(slide => slide.style.width = 'auto');
                }
            });
        });
    }
};