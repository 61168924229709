import Sticky from 'sticky-js';

(function ($) {
    var calculatorList = (function () {

        var _manualCrmQuantity = false;

        var _calculate = function () {
            var output = $('.calculatorList__output');
            var sum = 0;

            $('.calculatorList__item--checked').each(function (index) {
                var input = $(this).find('input');
                if (input.length) {
                    var multiply = $(this).find('input').data('multiply');
                    var base = $(this).data('base');
                    sum += base + (Number($(this).find('input').val()) * multiply) - multiply;
                } else {
                    sum += Number($(this).data('base'));
                }
            });

            var users = Number($('.calculatorList__item--crm input').val());
            $('.calculatorList__outputModules').text($('.calculatorList__item--checked').length);
            $('.calculatorList__outputUsers').text(users);



            var total_formatted = String(sum.toFixed((2))).replace(/\./g, '<span>,') + '</span>';
            output.html(total_formatted);
        };

        var _checkUsers = function () {
            var highestModuleValue = 0;
            var crmInput = $('.calculatorList__item--crm').find('input');
            var crmValue = crmInput.val();

            $('.calculatorList__item:not(.calculatorList__item--crm)').each(function (index) {
                let moduleValue = $(this).find('input').val();
                if (_manualCrmQuantity && parseInt(crmValue) < parseInt(moduleValue)) {
                    crmInput.val(moduleValue);
                } else if (!_manualCrmQuantity && parseInt(highestModuleValue) < parseInt(moduleValue)) {
                    highestModuleValue = $(this).find('input').val();
                    crmInput.val(highestModuleValue);
                }
            });
        };

        var _calculator = function () {
            var option = $('.calculatorList__item:not(.calculatorList__item--default)');
            option.on('click', '.calculatorList__textContainer', function (e) {
                var parents = $(this).parents('.calculatorList__item');
                if (parents.hasClass('calculatorList__item--checked')) {
                    parents.removeClass('calculatorList__item--checked').find('input').val(0);
                } else {
                    parents.addClass('calculatorList__item--checked').find('input').val(1);
                }
                _checkUsers();
                _calculate();
            });

            $('.calculatorList input').on('action_taken', function (e, arg) {
                if ($(this).closest('.calculatorList__item').hasClass('calculatorList__item--crm')) {
                    _manualCrmQuantity = true;
                }
                if ($(this).val() < 1) {
                    if (arg.action != 'keyup') {
                        $(this).closest('.calculatorList__item:not(.calculatorList__item--default)').removeClass('calculatorList__item--checked');
                        if ($(this).val() < 0) {
                            $(this).val('0');
                        }
                        if ($(this).closest('.calculatorList__item').hasClass('calculatorList__item--default')) {
                            $(this).val(1);
                        }
                        _checkUsers();
                        _calculate();
                    }
                } else {
                    if (!$(this).closest('.calculatorList__item').hasClass('calculatorList__item--crm') || arg.action != 'keyup') {
                        $(this).closest('.calculatorList__item').addClass('calculatorList__item--checked');
                        if ($(this).val() > 999) {
                            $(this).val(999);
                        }
                        _checkUsers();
                        _calculate();
                    }
                }
            })
                .blur(function () {
                    $(this).trigger('action_taken', {'action': 'blur'});
                })
                .change(function () {
                    $(this).trigger('action_taken', {'action': 'change'});
                })
                .keyup(function () {
                    $(this).trigger('action_taken', {'action': 'keyup'});
                });

            _calculate();
            var sticky = new Sticky('.calculatorList__calculator');
        };

        return {
            calculator: _calculator
        };
    })();

    $(function () {
        calculatorList.calculator();
    });

})(jQuery);