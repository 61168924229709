VIV.formProgress = {

    /**
     * Add event listeners
     */
    fire: (event, form_id, current_page) => {
        const form = document.getElementById('gform_wrapper_' + form_id);
        const modal = form && form.closest('.modal');
        current_page = current_page || null;

        // Dont run this script for non modal forms
        if (!modal || !modal.querySelector('.gf_page_steps')) {
            return;
        }

        const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

        // Get elements
        const title = modal.querySelector('.gf_step_active h2');
        const number = modal.querySelector('.gf_step_active .gf_step_number');
        const subTitle = modal.querySelector('.gf_step_active h3');
        const titleDestination = modal.querySelector('.modal__headerTitle');
        const subTitleDestination = modal.querySelector('.modal__headerSubtitle');
        const progress = modal.querySelector('.modal__progress');
        const steps = modal.querySelectorAll('.gf_step');
        const header = modal.querySelector('.modal__header');
        const side = modal.querySelector('.modal__side');
        const name = modal.querySelector('.name input');

        modal.classList.remove('modal--small');

        // Copy title from current step to header
        if (title && titleDestination) {
            titleDestination.innerHTML = title.innerHTML;
            const namePlaceholder = titleDestination.querySelector('[data-name]');
            if (namePlaceholder) {
                namePlaceholder.innerHTML = `${capitalizeFirstLetter(name.value)}!`;
            }
        }

        // Copy subtitle from current step to header
        if (subTitle && subTitleDestination) {
            subTitleDestination.innerHTML = subTitle.innerHTML;
        }

        if (progress) {
            let progressLinesHtml = '';
            for (let i = 0; i < steps.length; i++) {
                const classes = [
                    (i === (current_page - 1) ? 'current' : ''),
                    (i <= (current_page - 1) ? 'done' : ''),
                ];
                progressLinesHtml += `<span class="${classes.join(' ')}">${i}</span>`;
            }
            progress.innerHTML = progressLinesHtml;
        }

        if (parseInt(current_page) === 1 || !current_page) {
            modal.classList.add('modal--wide');
            if (header) {
                header.style.display = 'none';
            }
            if (side) {
                side.style.display = 'block';
            }
        } else {
            modal.classList.remove('modal--wide');
            if (header) {
                header.style.display = 'block';
            }
            if (side) {
                side.style.display = 'none';
            }
        }

        // Enable next buttons
        modal.querySelectorAll('.wizard-next').forEach(element => {
           element.addEventListener('click', e => {
               e.preventDefault();
               const nextButton = e.target.closest('.gform_page').querySelector('.gform_next_button');
               jQuery(nextButton).click();
           });
        });
    },
};

jQuery(document).on('gform_post_render', VIV.formProgress.fire);
