import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';


($ => {
    var MobileMenu = {

        rootElement: null,
        resizeTimer: null,

        init: function (rootElement) {

            const activeClass = 'navigationMobileOpen';

            $('.navigationTrigger').on('click', function () {
                var html = document.documentElement;
                html.classList.toggle(activeClass);

                if (html.classList.contains(activeClass)) {
                    disableBodyScroll($('.navigationMobile').get(0));
                } else {
                    enableBodyScroll($('.navigationMobile').get(0));
                }
            });

            // Init variables
            this.rootElement = $(rootElement);

            this.rootElement.find('ul .current-menu-ancestor ul').addClass('show');

            // Add back link
            $('<li class="navigation__backlink" aria-hidden="true"><a href="#">Terug</a></li>')
                .prependTo(this.rootElement.find('ul ul'))
                .on('click', this.backLinkClickHandler.bind(this));

            // Add submenu trigger and click handler
            $('<div class="submenuTrigger"></div>')
                .appendTo(this.rootElement.find('li.menu-item-has-children'))
                .on('click', this.triggerSubMenu.bind(this));

            $(window).on('resize', this.onResize.bind(this));
            this.onResize();

            if (this.rootElement.find('.current-menu-item').length) {
                var niv = this.rootElement.find('.current-menu-item').last().parents('ul').length;
                this.rootElement.attr('data-niveau', niv - 1);
            }
        },

        triggerSubMenu: function (e) {
            if ($('.navigationTrigger:visible').length) {
                e.preventDefault();
                this.rootElement.attr('data-niveau', $(e.target).parents('ul').length);
                $(e.target).parents('li').first().find('> ul').addClass('show');
            }
        },

        backLinkClickHandler: function (e) {
            e.preventDefault();
            var niv = this.rootElement.attr('data-niveau');
            var parent = $(e.target).parents('ul').first();
            this.rootElement.attr('data-niveau', niv - 1);

            setTimeout(function () {
                parent.removeClass('show');
            }.bind(this), 300);
        },

        reset: function () {
            $('.mobileMenu').height('auto') ;
            this.rootElement.find('ul ul').removeClass('show');
            if (this.rootElement.find('.current-menu-item').length) {
                var niv = this.rootElement.find('.current-menu-item').last().parents('ul').length;
                this.rootElement.attr('data-niveau', niv - 1);
            } else {
                this.rootElement.attr('data-niveau', 0);
            }
        },

        onResize: function () {
            $('.mobileMenu').height(window.innerHeight - $('.header').outerHeight());
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(function () {
                if (!$('.navigationTrigger:visible').length) {
                    this.reset();
                }
            }.bind(this), 10);
        }
    };

    $(document).ready(function () {
        MobileMenu.init('.navigationMobile');
    });
})(jQuery);
