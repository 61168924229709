(function ($) {
    class Form {

        static hasTouch() {
            return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        }

        // Init all functions
        static initAll(context)
        {
            Form.formPlaceholders(context);
            Form.formElementStates(context);
            Form.initDatePicker(context);

            if (!Form.hasTouch()) {
                Form.initSelect(context);
            }
        }

        static initSelect(context)
        {
            $('select', context).each(function () {
                const select = $(this);
                let label = select.closest('.gfield').find('label').text();
                const firstOption = select.find('option').first();
                if (!firstOption.attr('value')) {
                    label = firstOption.text();
                }

                if (!select.parent().attr('id')) {
                    const id = 'select-' + new Date().valueOf();
                    select.parent().attr('id', id);
                }

                if (select.find('option').first().val().trim()) {
                    select.prepend('<option></option>');
                }

                select.select2({
                    minimumResultsForSearch: 100,
                    dropdownParent: $(this).parent(),
                    placeholder: label,
                });
            });
        }

        static formElementStates(context)
        {
            context.querySelectorAll('input, textarea, select').forEach((element) => {
                // Skip wizard fields
                if (element.closest('.modal')) {
                    return false;
                }

                $(element).on('change blur keydown keyup', function () {
                    Form.checkInput(element);
                });

                Form.checkInput(element);
            });
        }

        static checkInput(input)
        {
            const $input = $(input);
            const $field = $input.parents('.gfield');

            // Skip hidden fields
            if ($input.attr('type') === 'hidden') {
                return false;
            }

            if ($input.val().trim()) {
                $field.addClass('gfield--filled');
            } else {
                $field.removeClass('gfield--filled');
            }
        }


        static formPlaceholders(context)
        {
            $('.gfield', context).each(function () {
                if ($(this).closest('.modal').length === 0) {
                    const input = $(this).find('input:not([type=radio]):not([type=checkbox]), textarea, select');
                    if (input.length) {
                        const label = $(this).find('label').first();
                        if (!input.attr('placeholder')) {
                            input.attr('placeholder', label.text());
                        }
                        label.addClass('label--floatLabel');
                    }
                }
            });
        }

        static initDatePicker(context)
        {
            if (typeof jQuery.datepicker != 'undefined') {
                const datePicker = $('input.datepicker', context);
                datePicker.datepicker('option', {
                    minDate: +1,
                    // maxDate: +30,
                    // firstDay: 1,
                    changeMonth: false,
                    changeYear: false,
                    beforeShowDay: function (date) {
                        var day = date.getDay();
                        if (day === 0 || day === 6 || day === 5) {
                            return [false];
                        }
                        return [true];
                    },
                    clearText: 'Effacer', clearStatus: '',
                    closeText: 'sluiten', closeStatus: 'Onveranderd sluiten ',
                    prevText: '<vorige', prevStatus: 'Zie de vorige maand',
                    nextText: 'volgende>', nextStatus: 'Zie de volgende maand',
                    currentText: 'Huidige', currentStatus: 'Bekijk de huidige maand',
                    monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
                    monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
                    monthStatus: 'Bekijk een andere maand', yearStatus: 'Bekijk nog een jaar',
                    weekHeader: 'Sm', weekStatus: '',
                    dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
                    dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
                    dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
                    dayStatus: 'Gebruik DD als de eerste dag van de week', dateStatus: 'Kies DD, MM d',
                    initStatus: 'Kies een datum', isRTL: false
                }).attr('readonly', 'readonly');
            }
        }
    }


    /**
     * Form behaviour
     */
    VIV.behaviours.form = {
        attach: function (context) {
            Form.initAll(context);
        }
    };

    $(document).on('gform_post_render', (e, id) => {
        setTimeout(function () {
            Form.initAll(document.getElementById('gform_wrapper_' + id));
        }, 100);
    });


})(jQuery);
