import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

VIV.behaviours.modal = {

    clickedOutsideEventHandler: null,
    mouseDownTarget: null,

    /**
     * Add event listeners
     */
    attach: () => {
        const behaviour = VIV.behaviours.modal;
        const modalTriggers = document.getElementsByClassName('modalTrigger');
        for (let modalTrigger of modalTriggers) {
            modalTrigger.addEventListener('click', behaviour.onTriggerClick);
        }

        // Click event on modal close button
        document.querySelectorAll('.modal .modal__close').forEach((closeButton) => {
            closeButton.addEventListener('click', behaviour.closeModal);
        });

        // Escape key listener to close modal
        document.addEventListener('keydown', (evt) => {
            evt = evt || window.event;
            if (evt.keyCode === 27) {
                behaviour.closeModal();
            }
        });

    },

    /**
     * onClick event handler
     *
     * @param event
     */
    onTriggerClick: (event) => {
        const behaviour = VIV.behaviours.modal;
        const target = event.target;
        event.preventDefault();
        if (target.hasAttribute('data-target')) {
            event.preventDefault();

            // Open previous opened modals
            behaviour.closeModal();

            // Close menu
            const navigationTriggers = document.getElementsByClassName('hamburger');
            const navigationOpen = document.documentElement.classList.contains('navigationOpen');
            if (navigationTriggers.length > 0 && navigationOpen) {
                navigationTriggers[0].dispatchEvent(new Event('click'));
            }

            // Get modal select from clicked link
            const linkTarget = target.getAttribute('data-target');
            let modal = document.querySelector(linkTarget);
            modal.classList.add('active');

            if (target.hasAttribute('data-video')) {
                const videoUrl = target.getAttribute('data-video');
                const videoWrapper = modal.querySelector('.modal__video');
                videoWrapper.innerHTML = `<div class="plyr__video-embed videoPlayer" data-autoplay="true">
                    <iframe src="${videoUrl}" allowFullScreen allowTransparency allow="autoplay" ></iframe>
                </div>`;
                VIV.attachBehaviours(videoWrapper);
            }

            jQuery(document).trigger('document.open_modal');

            // Open Modal
            disableBodyScroll(modal);
            document.documentElement.classList.add('modalOpen');
            document.getElementById('backdrop').classList.add('active');

            // Add click outside handler
            // setTimeout(() => behaviour.onClickOutside(modalContent, behaviour.closeModal), 1);
        }
    },

    /**
     * Close modal
     */
    closeModal: () => {
        const behaviour = VIV.behaviours.modal;
        let activeModal = document.querySelector('.modal.active');
        if (activeModal) {
            document.documentElement.classList.remove('modalOpen');
            activeModal.classList.remove('active');
            document.getElementById('backdrop').classList.remove('active');
            document.removeEventListener('click', behaviour.clickedOutside);
            enableBodyScroll(activeModal);

            if (activeModal.classList.contains('modal--video')) {
                activeModal.querySelector('.modal__video').innerHTML = '';
            }
        }
    },

    onClickOutside: (element, callback) => {
        const behaviour = VIV.behaviours.modal;
        behaviour.clickedOutside = (event) => {
            let isClickInside = element.contains(event.target);
            if (!isClickInside && event.target === behaviour.mouseDownTarget) {
                callback();
            }
        };
        document.addEventListener('click', behaviour.clickedOutside);
        document.addEventListener('mousedown', e => behaviour.mouseDownTarget = e.target);
    }
};