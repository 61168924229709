import Swiper from "swiper";

/**
 * Block images
 */
VIV.behaviours.submenuSlider = {

    // Attach behaviour
    attach: function (context) {
        context.querySelectorAll('.submenu__items').forEach(function (element) {
            const submenuSlider = new Swiper(element, {
                slidesPerView: 'auto',
                loop: false,
                watchOverflow: true,
                spaceBetween: 20,
                init: false
            });

            submenuSlider.on('resize', function (instance) {
                element.classList.toggle('submenu__items--slide', instance.virtualSize > instance.size);
            });

            submenuSlider.on('init', function (instance) {
                const activeSubmenuItem = element.getElementsByClassName('current-menu-item')[0];
                const submenuItems = element.getElementsByClassName('submenu__item');
                if (activeSubmenuItem) {
                    var index = [].indexOf.call(submenuItems, activeSubmenuItem);
                    submenuSlider.slideTo(index, 0);
                }
            });

            submenuSlider.on('breakpoint', (instance, params) => {
                if (params.slidesPerView === 'auto') {
                    // Reset width set by swiper
                    element.querySelectorAll('.swiper-slide').forEach(slide => slide.style.width = 'auto');
                }
            });

            submenuSlider.init();
        });
    }
};
