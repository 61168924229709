// Import all styles
import './styles/main.scss';

// Import js
import './scripts/index';

// Create static pages
import './twig/index.twig';
import './twig/888_hero.twig';
import './twig/888_visual.twig';
import './twig/888_callToAction.twig';
import './twig/888_testimonial.twig';
import './twig/888_testimonials.twig';
import './twig/888_text.twig';
import './twig/888_textOnImage.twig';
import './twig/888_accordion.twig';
import './twig/888_image.twig';
import './twig/888_solutions.twig';
import './twig/888_people.twig';
