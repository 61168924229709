(function($){

    /**
     * Scroll behaviour
     */
    VIV.behaviours.scrolled = {

        /**
         * Attach behaviour
         *
         * @param context
         */
        attach : function (context) {
            var $context = $(context);
            if ($context.find('body').length) {
                this.initOnScroll();
            }
        },

        /**
         * Add/Remove scrolled class on scroll
         */
        initOnScroll : function () {
            $(window).scroll(function () {
                var scrollTop = $(this).scrollTop();
                if (scrollTop > 10) {
                    document.documentElement.classList.add('scrolled');
                } else{
                    document.documentElement.classList.remove('scrolled');
                }
            });
        }
    };

})(jQuery);