VIV.behaviours.collapseItem = {
    attach: (context) => {
        const items = context.querySelectorAll('.collapseItem');
        const activeClass = 'collapseItem--open';
        items.forEach((item) => {
            item.querySelector('.collapseItem__header').addEventListener('click', (clickEvent) => {
                const group = item.closest('.collapseGroup');
                const classList = item.classList;
                clickEvent.preventDefault();

                if (classList.contains(activeClass) && group.classList.contains('collapseGroup--alwaysOneOpen') && jQuery('.navigationTrigger:visible').length === 0) {
                    return false;
                }

                if (classList.contains(activeClass)) {
                    classList.remove(activeClass);
                    jQuery(item).find('.collapseItem__content').slideUp(150);
                } else {
                    classList.add(activeClass);
                    jQuery(item).find('.collapseItem__content').slideDown(150);
                }

                let activeItems = group.querySelectorAll('.collapseItem--open');
                activeItems.forEach((activeItem) => {
                    if (activeItem !== item && jQuery('.navigationTrigger:visible').length === 0) {
                        activeItem.classList.remove(activeClass);
                        jQuery(activeItem).find('.collapseItem__content').slideUp(150);
                    }
                });

            });
        });
    }
};