import './behaviour';

import './elements/browserFeatures';
import './elements/scrolled';
import './elements/form';
import './elements/collapseItem';
import './elements/modal';
import './elements/video';
import './elements/wizard';
import './elements/MobileMenu';
import './elements/submenu';

import './block/calculator';
import './block/enroll';

import './sliders/testimonials';
import './sliders/solutions';
import './sliders/people';
import './sliders/submenu';
